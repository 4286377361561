@import '../../variables';

.popin {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .popin-mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000000, .4);
  }

  .popin-container {
    position: relative;
    background-color: #ffffff;
    box-shadow: 0 3px 5px 1px rgba(#000000, .25);
    border-radius: $border-radius;
    overflow: auto;
    display: flex;
    max-height: 95dvh;
    flex-direction: column;
    width: 90%;
    .scroll-contenaire-popup-login{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      overflow-y: auto;
      overflow-x: hidden;

      &.no-scroll{
        overflow: hidden;
      }
    }
    .contenaire_popup_inscription_top{
      display: flex;
      gap: $spacer-4;
      padding-bottom: $spacer-6;
      padding: $spacer-4;
      padding-top: $spacer-7;     
      height: 100%;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      border-bottom: solid 1px $border-color;

      &.mailvisible_box{
        gap: $spacer-4;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;

        .img_promo5_and_piece{
          width: 20%;
          display: flex;
          justify-content: center;
          align-items: center;
         
        }
        .popin_header_text{
          font-size: 1em;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          gap: $spacer-4;
          flex-direction: column;
        }
        .question_popins{
          text-align: start;
        }
      }
      
      .img_promo5_and_piece{
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
       
      }
      .popin_header_text{
        font-size: 1.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: $spacer-4;
        flex-direction: column;
        text-align: center;
      }
      
      
      
    }

   .contenaireInput_mailvisible{
      display: flex;
      gap: $spacer-4;
      padding: 0 $spacer-6;
      margin-bottom: $spacer-6;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      transition: all 0.5s ease;
      width: 100%;
      text-align: center;
      height: 100%;
      opacity: 0;
      animation: popin-appear 0.3s ease 0.2s forwards;
    }
    .methode_group{
      display: flex;
      gap: 1em;
      text-align: center;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 2em;
      position: relative;
    
      &.borderbottom::after{
        width: 90%;
        content: '';
        height: 1px;
        background-color: $border-color;
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .close-btn {
      position: absolute;
      right: $spacer-4;
      top: $spacer-2;
      width: 40px;
    }
  }
}
.question_popins{
  font-size: $font-size-small;
  margin-bottom: 2em;
  margin-top: 2em;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;


  span{
    font-weight: 600;
    color: #3523B9;
  }
}

@keyframes popin-appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}