.deletepayment{
    display: flex;
    width: 80px;

    justify-content: flex-end;
    align-items: center;
}
.payment-popup-content{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.titre_popup{
    font-size: 22px;
    word-wrap: break-word;
    font-family: 'geomanistmedium';
    font-weight: 500;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;

    span{
        max-width: 156px;
    }
}