@import '../../variables';

.reward-screen {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .reward-container {
        display: flex;
        height: 100%    ;
        flex-direction: column;
        height: calc(100% - $height-pikko-point-header);
        padding: 15px;
        gap: 15px;
        margin-bottom: 50px;
      
       
    }
}
