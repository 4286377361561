@import '../../variables';

.kr-iframe-wrapper{
  border: 1px solid black;
}
.kr-embedded .kr-wallet-tabs .kr-wallet-tab{
  //display: none;
}
.kr-layer-manager .kr-simple-modal-wrapper .kr-simple-modal .kr-simple-modal-footer button{
  position: absolute;
  bottom: 100px;
}

#cbpopup {

  .kr-card-form {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .kr-card-header {
    display: none !important;
  }

  .kr-embedded .kr-field.kr-checkbox .kr-field-control:hover input:not([disabled]):checked ~ .kr-checkbox-control-indicator { // to override checkbox color
    background: #e1ff26;
  }

  .kr-card-holder-mail { // input email
    margin-top: $spacer-5;
    padding: $spacer-1 $spacer-2;
    padding-left: $spacer-4;
    border-radius: $border-radius;
    border: 1px solid $gray-light;

    .kr-field-component { // size of font inside email input
      font-size: 16px;
    }
  }

  .kr-embedded .kr-field-wrapper .kr-field-component { // to align placeholder and text inside credit card inputs
    display: flex;
    align-items: center;
  }

  .kr-visibility-button {
    display: none;
  }

  .kr-smart-form {
    width: 100%;
    margin-top: $spacer-7;

    .kr-field-element {
      width: 100%;
      margin-bottom: $spacer-4;
      height: 45px;

      &.kr-expiry {
        width: 49%;
        margin-right: 2%;
      }

      &.kr-security-code {
        width: 49%;
      }

      .kr-iframe-wrapper {
        padding: $spacer-1 $spacer-2;
        border-radius: $border-radius;
        border: 1px solid $gray-light;
      }
    }

    .kr-form-error {
      display: none;
    }

    .kr-checkbox-input-wrapper {
      margin-top: $spacer-3;
      margin-left: $spacer-2;

      .kr-checkbox {
        display: flex;
        align-items: center;

        .kr-checkbox-control-indicator {
          height: 20px;
          width: 20px;
          border-radius: $border-radius-light;
        }

        .kr-label {
          margin-left: $spacer-2;
          font-size: 14px;
        }
      }
    }
  }

  .kr-inner-wrapper {
    font-size: 16px;
    margin-left: $spacer-2;
    margin-top: $spacer-2;
  }

  .kr-embedded input.kr-input-field {
    height: 20px;
  }

  .kr-payment-button {
    background: linear-gradient(140deg, rgba(225, 255, 38, 1) 15%, rgb(255, 237, 71) 100%);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    border-radius: 8px;
    color: $black;
    font-family: 'geomanistmedium';
    height: 50px;
    width: 100%;

    margin-bottom: 20px;
  }

  .kr-custom-error {
    text-align: center;
    color: $red;
    font-size: $font-size-normal;
    margin-bottom: $spacer-4;
  }
}
