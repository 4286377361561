@import '../variables';

.welcome_screen_principal_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;


    @keyframes gravity {
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(10px);
        }
        100% {
            transform: translateY(0);
        }
    }
    @keyframes gravity_left {
        0% {
            transform: translateY(0) scaleX(-1);
        }
        50% {
            transform: translateY(10px) scaleX(-1);
        }
        100% {
            transform: translateY(0) scaleX(-1);
        }

    }

    .question-mark {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 20px;
        right: 20px;
        width: 35px;
        height: 35px;
        padding: 5px;
        border-radius: $border-radius-round;
        background: $white;
        border: 1px solid lightgrey;
        z-index: 100;
    }

    .logo-brand {
        position: absolute;
        top: 10px;
        z-index: 100;

        img {
            width: 113px;
        }
    }

    .welcome_screen_secondary_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        gap: 16px;
        padding: 25px 20px;
        overflow-y: scroll;
        padding-bottom: 100px;


        .logo_and_welcome_text_container {
            display: flex;
            position: relative;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 40px;

            .pikko-profile {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 70px;
                margin-bottom: 30px;

                .pikko-mail {
                    margin-top: 10px;
                    font-size: 14px;
                    font-family: 'geomanistregular';
                }

                img {
                    width: 84px;
                }
            }

            .pikko-animation {
                width: 10rem;
            }

            .welcome_text_container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-top: 15px;
                gap: 8px;

                .welcome_text_container_bold {
                    font-size: clamp(2em, 8vw, 3.5em);
                    font-weight: 600;
                    text-align: center;
                }

                .welcome_text_container_light {
                    font-size: clamp(1.5em, 5vw, 2.5em);
                    font-weight: 400;
                    opacity: 0.7;
                    font-family: 'open sans';
                    text-align: center;
                }
            }
        }

        .connection_or_invitation {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            gap: 16px;

            .connection_button {
                display: flex;
                padding: 16px;
                width: 100%;
                font-size: 16px;
                box-shadow: 0 8px 0 #D9CA0C;
            }

            .deploy_button {
                width: 100%;
                padding: 16px;
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                background-color: #ffffff;
                border: #E4DCDC solid 1px;
                width: 100%;
                border-radius: 10px;
                transition: 0.3s;
                font-family: 'geomanistmedium';

            }
        }
    }
}

.slider {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .slider-mask {
        background-color: rgba(#000000, .6);
    }
}

  .tuto_box {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 4rem;
    overflow-y: scroll;
    padding-top: clamp(4rem, 20vh, 20rem);
    padding-bottom: 150px;
  }

  .tuto-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    .tuto-bold{
        font-size: 2.5rem;
        font-weight: 900;
        font-family: 'geomanistmedium';
        }
  }

  .tuto-container img {
    max-width: 100%;
  }

  .tuto-title {
  display: flex;
    font-size: 14px;
    text-align: center;
    width: 100%;
  }

  .coco-leaf-right {
    position: absolute;
    right: 0;
    top: -5px;
    width: 120px;
    animation:  gravity 3s infinite 1s;
}

.coco-leaf-left {
    position: absolute;
    left: 0;
    top: -5px;
    width: 120px;
    transform: scaleX(-1);
    animation:  gravity_left 3s infinite;
}